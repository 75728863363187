import crypto from "crypto";
import { ALGORITHM, ENC_PASSWORD } from "@/config";
import { getCookie } from "../helper/cookie";

export function getIV() {
  const queryString = getCookie("qs");
  const urlDecoded = decodeURIComponent(queryString).replace(/ /g, "+");
  const position = urlDecoded.search("enc=");
  let encString = urlDecoded.slice(position + 4);
  const parts = encString.split(':');
  return Buffer.from(parts[0], 'base64');
}

export default function regex(regx, value) {
  const regex = new RegExp(regx);
  return regex.test(value);
}

export const isObject = v => typeof v === 'object' && v !== null

export const isEmpty = v =>
  !v ||
  (Array.isArray(v) && v.length === 0) ||
  (isObject(v) && Object.keys(v).length === 0);

export function formatNumberWithComma(value) {
  return value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeFormatNumberWithComma(value) {
  return value.toString().replace(/,/g, '');
}

const AusState = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

const removeValue = (arr, index) => {
  arr.splice(index, 1);
};

export const LocationValMap = (val) => {
  // Split location string into array
  let locationArray = val.toUpperCase().split(' ');

  // Init
  let state = '';
  let postCode = '';

  // Detect state
  const isSingleWord = (str) => str.split(' ').length === 1;

  for (const region of AusState) {
    const includesRegion = isSingleWord(region)
      ? locationArray.includes(region)
      : locationArray.join(' ').includes(region);

    if (includesRegion) {
      state = region.toUpperCase();
      break; // exit the loop early if a match is found
    }
  }

  // Remove value if valid
  if (state !== '') {
    if (isSingleWord(state)) {
      removeValue(
        locationArray,
        locationArray.findIndex((value) => value === state),
      );
    } else {
      locationArray = val
        .toUpperCase()
        .replace(state, '')
        .split(' ')
        .filter((string) => string);
    }
  }

  // Detect postcode and remove value if valid
  locationArray.forEach((value, index) => {
    if (!isNaN(+value)) {
      postCode = value;
      removeValue(locationArray, index);
    }
  });

  // Create surbub value with the rest of splitted location string array
  const suburb = locationArray.join(' ').toUpperCase();

  return {
    suburb,
    state,
    postCode,
  };
};

function cipher(mode, key, iv, data) {
  const encipher = crypto[mode](ALGORITHM, key, iv);
  let encoded  = encipher.update(data);

  encoded += encipher.final();
  return encoded;
}

export const decrypt = (data, key = ENC_PASSWORD, iv = getIV()) => {
  return cipher('createDecipheriv', key, iv, data);
}

export const b64dec = (data) => {
  return Buffer.from(data, 'base64');
}

export function apiEndpointTransform(hostname) {
  if (['localhost', 'autoflip.co.nz'].some(host => hostname.includes(host)))
    return process.env.VUE_APP_BASE_API_ENDPOINT

  const isNotProd = hostname.includes('psp-')
  let backendHost = ''
  if (isNotProd) {
      backendHost = `api.${hostname}`
  } else {
      backendHost = hostname.replace(/sell|mycar/gi, "api");
  }
  return `https://${backendHost}`
}