import Vue from 'vue'
import Vuex from 'vuex'
import regoSearch from './rego-search'
import common from '@/store/common'
import info from '@/store/info'
import photo from '@/store/photo-upload'
import { sessionStoragePersistence } from './plugins/session-storage-persistence'
// import { localStoragePersistence } from './plugins/local-storage-persistence'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        info,
        regoSearch,
        common,
        photo
    },
    plugins: [
        sessionStoragePersistence({
            reducer: (state) => {
                const { common, info, regoSearch, photo } = state;
                return { common, info, regoSearch, photo };
            },
            mutationFilter: ({ type }) => {
                const ignoreMutations = [
                    'setFetchTokenPromise',
                    'setUploadedImages',
                    'clearImages',
                    'setSendPhotoLater',
                    'clearSendPhotoLater',
                ]
                return !ignoreMutations.includes(type);
            }
        })
        // localStoragePersistence({
        //     reducer: (state) => {
        //         const { common, info, regoSearch, photo } = state;
        //         return { common, info, regoSearch, photo };
        //     },
        //     mutationFilter: ({ type }) => {
        //         const ignoreMutations = [
        //             'setFetchTokenPromise',
        //             'setUploadedImages',
        //             'clearImages',
        //             'setSendPhotoLater',
        //             'clearSendPhotoLater',
        //         ]
        //         return !ignoreMutations.includes(type);
        //     }
        // })
    ]
})

export default store
